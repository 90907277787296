import React from 'react';
import adImage from './images/vert.webp';

const AdComponent = () => {
  return (
    <div className="vert-container">
      <a
        href="https://autotone.shop/products/simpliauto-7-android-auto-stereo-104222589244-1q3lv"
        target="_blank"
        rel="noopener noreferrer"
        className="vert-link"
      >
        <div className="vert-content">
          <img
            src={adImage}
            alt="Android Head Units Sale"
            className="vert-image"
          />
          <div className="vert-text">
            <h4>30% Off All Android Head Units!</h4>
            <p>Upgrade your ride with the latest Android head units. Limited time offer!</p>
            <button className="vert-button">Shop Now</button>
          </div>
        </div>
      </a>
    </div>
  );
};

export default AdComponent;