import React from "react";

const ModelList = () => {
  const models = {
    "ND Series": [
      "ND3T-W56",
      "ND3T-W57",
      "NDCN-D55",
      "NDCN-W55",
      "NDDA-W55",
      "NDDA-W56",
      "NDDN-W56",
      "NDDN-W57",
      "NDDN-W58",
    ],
    "NH Series": [
      "NH3N-W58",
      "NH3T-W57",
      "NHBA-W62G",
      "NHDA-W61G",
      "NHDT-W57",
      "NHDT-W58",
      "NHDT-W58G",
      "NHDT-W59",
      "NHDT-W59G",
      "NHDT-W60G",
      "NHZD-W62G",
      "NHZN-W57",
      "NHZN-W58",
      "NHZN-W59C",
      "NHZN-W59G",
      "NHZN-W60G",
      "NHZN-W61",
      "NHZN-W61G",
      "NHZT-W58",
      "NHZT-W58G",
    ],
    "NS Series": [
      "NSCD-W66",
      "NSCN-W59C",
      "NSCN-W60",
      "NSCP-W61",
      "NSCP-W62",
      "NSCP-W64",
      "NSCT-W61",
      "NSDD-W61",
      "NSDN-W59",
      "NSDN-W60",
      "NSDT-W59",
      "NSZA-X64T",
      "NSZT-W60",
      "NSZT-W61G",
      "NSZT-W62G",
      "NSZT-W64",
      "NSZT-Y66T",
      "NSZT-W66T",
    ],
    "NM Series": ["NMCN-D51", "NMCN-W51M", "NMCT-D51", "NMCT-W51"],
    "DS Series": ["DSZT-YC4T"]
  };

  return (
    <div className="model-list">
      {Object.entries(models).map(([series, items]) => (
        <div className="section" key={series}>
          <h2>{series}</h2>
          <ul>
            {items.map((model) => (
              <li key={model}>{model}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default ModelList;