import React, { useState, useRef } from 'react';
import { DecodeErc } from './ErcCalculation';
import JsonLdComponent from './JsonLdComponent';
import InlineStyles from './InlineStyles';
import ModelList from "./SupportComp";
import AdComponent from "./AdComp";

function App() {
    const [inputCode, setInputCode] = useState('');
    const [decodedResult, setDecodedResult] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        if (inputCode.length !== 16) {
            setErrorMessage('Invalid number of characters.');
            setDecodedResult('');
        } else {
            setErrorMessage('');
            const result = DecodeErc(inputCode);
            setDecodedResult(result);
        }
    };

    const handleGenerateAnother = () => {
        setInputCode('');
        setDecodedResult('');
        setErrorMessage('');
    };

    const whatIsRef = useRef(null);
    const howToRef = useRef(null);
    const executeWhatIsScroll = () => whatIsRef.current.scrollIntoView();
    const executeHowToScroll = () => howToRef.current.scrollIntoView();

    return (
        <div className="app-container">
            <JsonLdComponent /> {/* Include the JSON-LD component */}
            <InlineStyles /> {/* Include the inline styles component */}
            <div className="erc-decoder">
                <h1>Free Toyota ERC Headunit Unlock Tool</h1>


                <div className="explanation">
                    <h4>Our tool provides a quick and easy way to generate ERC unlock codes for free, helping you restore full functionality to your Japanese Toyota head unit without the need for costly dealership visits.</h4>
                </div>

                <div className="navigationButtons">
                  <button onClick={executeWhatIsScroll}>What is ERC?</button>
                  <button onClick={executeHowToScroll}>How do i find my 16 digit code?</button>
                </div>
                
                <div className="erc-form">
                  <form onSubmit={handleSubmit}>
                      <label>
                          ERC Code (16 characters):
                          <input
                              type="text"
                              placeholder='e.g. 9F8E7D6C5B4A3210'
                              value={inputCode}
                              onChange={(e) => setInputCode(e.target.value)}
                              maxLength={16}
                              minLength={16}
                              required
                          />
                      </label>
                      <button id="ercDecodeButton" type="submit">Decode</button>
                      {errorMessage && <p className="error-message">{errorMessage}</p>}
                {decodedResult && (
                    <div className="decoded-result">
                        <p>Decoded ERC: {decodedResult}</p>
                        <button className="generate-button" onClick={handleGenerateAnother}>Generate Another Code</button>
                    </div>
                )}
                  </form>
                  <AdComponent />
                </div>

                

                <div className='images'>
                  <div>
                    <img alt='locked out headunit'  src="images/SecurityQuestion.webp" />
                  </div>
                  <div>
                    <img alt='erc unlock screen' src="images/ERCserial.webp" />
                  </div>
                </div>

                <div ref={whatIsRef}>
                  <h2>What is ERC?</h2>
                  <p>The Emergency Rescue Code (ERC) is a unique, vehicle-specific code used to unlock the head unit of certain Japanese Toyota vehicles. This code is necessary when the head unit becomes locked, which can occur due to security features activated after events like a battery disconnection or incorrect entry attempts. Without the correct ERC, the head unit remains inoperable, preventing access to important functions such as navigation, audio, and other in-car systems. ERCs ensure that only authorized users can regain control of the head unit, thus enhancing the security of the vehicle’s infotainment system.</p>
                </div>

                <div ref={howToRef}>
                  <h2>How do i find my 16 digit code?</h2>
                  <p>Finding your 16-digit ERC (Emergency Rescue Code) for your Toyota head unit can vary depending on your vehicle model. While some cars may follow a straightforward process, others might require additional research or different steps. 
                    Here's a general guide to help you retrieve your ERC code:
                    <br/> <h4>To get the ERC Unlock Code, first you need to retrieve the ERC Serial Number from your car's head unit by following these steps:</h4>

                    1. Press and Hold the Main Button: On your navigation player, press and hold the main button.
                    <br/><br/>2. Turn Parking Lights On/Off: Switch your parking lights on and off 3 to 4 times until a new screen appears on the head unit.
                    <br/><br/>3. Press the Active Buttons: Follow the prompts on the screen and press only the active buttons. This will lead you to a screen displaying the 16-digit ERC serial number. Examples of ERC serial numbers include 12345ABC6789DEFG and 9F8E7D6C5B4A3210.
                    <br/><br/>4. If these steps do not work for your specific model, you may need to research the procedure for your particular vehicle or consult additional resources.
                  </p>
                </div>
                
                

                <div style={{marginTop: '50px'}}>
                  <h1>Supported Models</h1>
                  <p>Our ERC unlock tool supports a wide range of Toyota head units, including the following models:</p>
                  <ModelList />
                </div>
                
                <div class="fb-like" data-href="https://www.facebook.com/people/ErcUnlockcom/61561749016493/" data-width="100" data-layout="" data-action="" data-size="" data-share="true"></div>
            </div>
        </div>
    );
}

export default App;
