import React from 'react';

const InlineStyles = () => {
  const styles = `
    body {
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }


    .app-container {
      display: flex;
      justify-content: center;
      align-items: top;
      padding-top: 50px;
      background-color: #DDDDDD;
      font-family: Arial, sans-serif;
      color: #DDDDDD;
    }

    .erc-decoder {
      background-color: #30475E;
      padding: 20px;
      border-radius: 15px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      width: 60vw;
      text-align: center;
      margin-bottom: 50px;
    }

    @media (max-width: 1000px) {
      .erc-decoder {
        width: 80vw;
      }
    }

    .navigationButtons {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 50px 0;
    }
    @media (max-width: 550px) {
      .navigationButtons {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 110px;
      }
    }

    .navigationButtons button {
      width: 200px;
      background-color: #222831 !important;
      border-radius: 5px;
      border: 1px solid black;
      margin: 0 50px;
      text-decoration: underline;
    }

    .erc-decoder h2 {
      margin-bottom: 20px;
    }

    .erc-decoder .explanation {
      text-align: left;
      margin: 20px auto;
      max-width: 500px;
      display: flex;
      flex-direction: column;
      color: #F05454;
    }

    .erc-decoder .explanation p {
      margin-bottom: 10px;
    }

    .erc-decoder .explanation h4 {
      margin-bottom: 10px;
      color: #F05454;
      text-align: center;
    }

    .erc-decoder form {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #222831;
      border-radius: 15px;
      max-width: 300px;
      margin: 10px auto;
      padding: 20px;
    }

    .erc-decoder label {
      margin-bottom: 15px;
      font-weight: bold;
    }
    .erc-decoder label input {
      margin-top: 5px;
    }

    .images {
      display: flex;
      justify-content: center;
      margin: 50px 0;
    }

    .images div {
      margin: 0 20px;
    }
    .images div img {
      width: 20vw;
    }
    @media (max-width: 1000px) {
      .images div img {
        width: 30vw;
      }
    }
    @media (max-width: 420px) {
      .images div img {
        width: 50vw;
      }
      .images {
        flex-direction: column;
      }
      .images div {
        margin: 10px 0;
      }
    }

    .erc-decoder input[type="text"] {
      padding: 8px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 4px;
      outline: none;
      text-align: center;
    }

    .erc-decoder input[type="text"]:focus {
      border-color: #007bff;
    }

    .erc-decoder button {
      padding: 10px 20px;
      font-size: 16px;
      background-color: #007bff;
      color: #ffffff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }

    .erc-decoder button:hover {
      background-color: #0056b3;
    }

    .erc-decoder .error-message {
      color: #dc3545;
      margin-top: 10px;
    }

    .erc-decoder .decoded-result {
      margin-top: 20px;
    }
    
    .decoded-result p {
      padding: 10px;
      background-color:rgb(155, 238, 159);
      color: black;
      border-radius: 5px;
      width: 200px;
      margin: 0 auto;
      font-weight: bold;
    }

    .vert-container {
      display: flex;
      width: 270px;
      border: 1px solid #ddd;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      font-family: Arial, sans-serif;
      margin: 10px auto;
    }

    .vert-link {
      text-decoration: none;
      color: inherit;
    }

    .vert-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fff;
    }

    .vert-image {
      width: 100%;
      height: auto;
    }

    .vert-text {
      padding: 15px;
      text-align: center;
    }

    .vert-text h4 {
      font-size: 18px;
      margin: 10px 0;
      color: #333;
    }

    .vert-text p {
      font-size: 14px;
      color: #555;
    }

    .vert-button {
      margin-top: 10px;
      padding: 10px 20px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      font-size: 14px;
    }

    .vert-button:hover {
      background-color: #0056b3;
    }

    .erc-decoder .generate-button {
      margin-top: 20px;
    }

    #u_0_4_aM {
        color: white;
    }

    .model-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
    } 

    .erc-form {
      display: flex;
      flex-direction: row; /* Default direction for larger screens */
    }

    @media (max-width: 768px) {
      .erc-form {
        flex-direction: column-reverse; /* Reverse column direction for mobile */
      }
    }
      .erc-form form {
      height: 230px;
    }

  `;

  return (
    <style>
      {styles}
    </style>
  );
};

export default InlineStyles;
